import colourConfig from '../../config/colourConfig';
import InfoPanelAccordion from './InfoPanelAccordion/InfoPanelAccordion';
import './LegendLayerInfoControlPanel.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';


// LegendLayerInfoControlPanel component: A control panel that displays legend and layer information
// Props:
// - entities: object containing dataset entities
// - dataLayers: Current array of layer objects
// - setDataLayers: Function to set the dataLayers state of the parent component
const LegendLayerInfoControlPanel = ({ entities, dataLayers, setDataLayers }) => {

  const handleCheckboxChange = (index) => {
    const updatedDataLayers = [...dataLayers];
    updatedDataLayers[index].visible = !updatedDataLayers[index].visible;
    setDataLayers(updatedDataLayers);
  };

  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      handleCheckboxChange(index);
    }
  };

  return (
    <div data-testid="control-panel-container">
      <div className='controlPanelHeader' data-testid="control-panel-header">
  
        <h2 className='headerTitle' data-testid="header-title">
        <FontAwesomeIcon icon={faLayerGroup} />
          Layer control
          </h2>
        </div>
          <div data-testid="legend-layer-info-control-panel">
            {dataLayers.length === 0 && (
                  <p className="legend-info" data-testid="legend-info">
                    Navigate to your area of interest and select the area by dropping a pin on the desired location.
                  </p>
                )}
                <div className="legend-container" data-testid="legend-container">
              {entities?.map((entity, index) => (
                <div className="legend-item-container" key={index}>
                <div className="legend-item" data-testid={`legend-${index}`}>
                    <div 
                      className="legend-color" 
                      style={{ padding: '10px', width: '5px', backgroundColor: colourConfig[entity.dataset] || colourConfig['default'] }} 
                      data-testid={`legend-color-${index}`}
                    >
                    </div>
                    <input
                      type="checkbox"
                      id={`dataset-${index}`}
                      name={`dataset-${index}`}
                      checked={dataLayers[index]?.visible ?? false}
                      onChange={() => handleCheckboxChange(index)}
                      onKeyDown={(event) => handleKeyDown(event, index)}  
                      data-testid={`checkbox-${index}`}
                    />
                    <InfoPanelAccordion entity={entity} index={index}/>
                </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default LegendLayerInfoControlPanel;
