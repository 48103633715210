import { useEffect, useRef, useState } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat, toLonLat } from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Style, Icon, Fill, Stroke } from 'ol/style';
import pinIcon from './location.png';
import { getPlanningData, getOSNames } from '../../Services/apiServices';
import WKT from 'ol/format/WKT';
import colourConfig from '../../config/colourConfig';
import LegendLayerInfoControlPanel from '../LegendLayerInfoControlPanel/LegendLayerInfoControlPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ClearButton from '../ClearButton/ClearButton';
import styles from './Map.module.css';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { transform } from 'ol/proj';
import SearchInput from '../SearchInput/SearchInput';

// Register the EPSG:27700 projection
proj4.defs("EPSG:27700", "+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +datum=OSGB36 +units=m +no_defs");
register(proj4);

const MapComponent = () => {
  const mapRef = useRef(null);
  const vectorSourceRef = useRef(new VectorSource());
  const [map, setMap] = useState(null);
  const [pin, setPin] = useState(null);
  const [dataLayers, setDataLayers] = useState([]);
  const [legendData, setLegendData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const extent = [
    fromLonLat([-8.649357, 49.906193]),
    fromLonLat([1.768960, 60.847358])
  ];

  // Initialise map
  useEffect(() => {
    const initialMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        new VectorLayer({
          source: vectorSourceRef.current,
        }),
      ],
      view: new View({
        center: fromLonLat([-1.24808, 52.69969]),
        zoom: 6,
        extent: extent.flat(),
      }),
    });

    setMap(initialMap);
    return () => initialMap.setTarget(null);
    // eslint-disable-next-line
  }, []);

  const handleSearch = async () => {
    try {
      const response = await getOSNames(searchQuery);
      if (response && response.results) {
        setSearchResults(response.results);
        setErrorMessage("");
      } else {
        setErrorMessage("Location not found");
      }
    } catch (error) {
      setErrorMessage("Error fetching location data");
    }
  };

  const handleResultClick = (result) => {
    const { GEOMETRY_X, GEOMETRY_Y } = result.GAZETTEER_ENTRY;
    // Transform coordinates from EPSG:27700 to EPSG:3857
    const coordinates = transform([GEOMETRY_X, GEOMETRY_Y], 'EPSG:27700', 'EPSG:3857');
    const view = map.getView();
    view.setCenter(coordinates);
    view.setZoom(18);
    setSearchResults([]);
  };

  const handleSearchResultKeyDown = (event, result) => {
    if (event.key === 'Enter') {
      handleResultClick(result);
    }
  };

  // Add click handler after map is initialised
  useEffect(() => {
    if (!map) return;

    const handleClick = (event) => {
      const coordinates = event.coordinate;
      addPin(coordinates);
    };

    map.on('click', handleClick);

    return () => map.un('click', handleClick);
    // eslint-disable-next-line
  }, [map]);

  // Handle data visualisation layers
  useEffect(() => {
    if (!map) return;

    // Clear existing data layers
    dataLayers.forEach(layer => map.removeLayer(layer));

    // Add new layers
    dataLayers.forEach(layer => {
      if (layer.visible) {
        map.addLayer(layer);
      }
    });
  }, [map, dataLayers]);

  const addPin = async (coordinates) => {
    if (!map) return;
    setLoading(true);

    // if (pin) {
    //   setErrorMessage('Please clear the existing pin before adding a new one.');
    //   return;
    // }

    vectorSourceRef.current.clear();
    setErrorMessage("");
    if (pin) {
      vectorSourceRef.current.removeFeature(pin);
    }

    const pinFeature = new Feature({
      geometry: new Point(coordinates),
    });

    pinFeature.setStyle(
      new Style({
        image: new Icon({
          src: pinIcon,
          anchor: [0.5, 1],
        }),
      })
    );

    vectorSourceRef.current.addFeature(pinFeature);
    setPin(pinFeature);

    const [longitude, latitude] = toLonLat(coordinates);

    try {
      const response = await getPlanningData({
        longitude,
        latitude,
        geometry_relation: 'touches',
      });

      // Check if response has entities property
      if (response && response.entities) {
        visualiseData(response.entities);
        setLegendData(response.entities);
        setErrorMessage("");
      } else {
        console.error("Invalid response format:", response);
        setErrorMessage(`Error: ${response.status} - ${response.statusText}`);
        // Clear existing layers if no valid data
        dataLayers.forEach((layer) => map.removeLayer(layer));
        setDataLayers([]);
        setLegendData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage(
        error.message + " - Please try again or contact help for support" ||
          "Error fetching data"
      );
      // Clear existing layers on error
      dataLayers.forEach((layer) => map.removeLayer(layer));
      setDataLayers([]);
      setLegendData([]);
    } finally{
      setLoading(false);
    }
  };

  const visualiseData = (entities) => {
    if (!map) return;

    try {
      const newLayers = entities.map((entity, index) => {
        const color = colourConfig[entity.dataset] || colourConfig['default']; // Use colour from config or default value if not found
        const wktFormat = new WKT();

        // Transform WKT coordinates to map projection
        const feature = wktFormat.readFeature(entity.geometry, {
          dataProjection: 'EPSG:4326', // WGS 84 (latitude/longitude)
          featureProjection: 'EPSG:3857', // map projection
        });

        feature.setStyle(
          new Style({
            stroke: new Stroke({
              color: color.replace('0.4', '1'),
              width: 2,
            }),
            fill: new Fill({
              color: color,
            }),
          })
        );

        const vectorSource = new VectorSource({
          features: [feature],
        });

        return new VectorLayer({
          source: vectorSource,
          zIndex: 1,
        });
      });

      setDataLayers(newLayers);

      // Fit the view to the features if there are any
      if (newLayers.length > 0) {
        const allFeatures = newLayers.flatMap(layer => 
          layer.getSource().getFeatures()
        );

        const combinedExtent = allFeatures.reduce((extent, feature) => {
          const geometryExtent = feature.getGeometry().getExtent();
          return extent
            ? [
                Math.min(extent[0], geometryExtent[0]),
                Math.min(extent[1], geometryExtent[1]),
                Math.max(extent[2], geometryExtent[2]),
                Math.max(extent[3], geometryExtent[3]),
              ]
            : geometryExtent;
        }, null);

        if (combinedExtent) {
          map.getView().fit(combinedExtent, {
            padding: [50, 50, 50, 50],
            duration: 1000,
          });
        }
      }
    } catch (error) {
      console.error("Error processing geometries:", error);
    }
  };

  const clearPin = () => {
    vectorSourceRef.current.clear();
    setPin(null);
    dataLayers.forEach(layer => map.removeLayer(layer));
    setDataLayers(dataLayers);
  };

  const [isPanelExpanded, setIsPanelExpanded] = useState(true);

  const togglePanel = () => {
    setIsPanelExpanded(!isPanelExpanded);
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.leftPanel} ${isPanelExpanded ? styles.leftHandPanelOpen : styles.leftHandPanelClosed}`}>
        {isPanelExpanded && (
          <>
            <LegendLayerInfoControlPanel
              entities={legendData}
              dataLayers={dataLayers}
              setDataLayers={setDataLayers}
            />
          </>
        )}
        <button onClick={togglePanel} className={styles.leftHandPanelButton} aria-label='Toggle panel'>
          {isPanelExpanded ? (
            <FontAwesomeIcon icon={faChevronLeft} />
          ) : (
            <FontAwesomeIcon icon={faChevronRight} />
          )}
        </button>
      </div>
      <div className={styles.flexContainer}>
        <div className={styles.flexCenter}>
          <SearchInput 
            inputValue={searchQuery} 
            handleInputChange={(e) => setSearchQuery(e.target.value)} 
            handleSearch={handleSearch} 
            placeholder="Search for a location"
          />
          <ClearButton onClick={clearPin} buttonText={'Clear pin'}/>
        </div>
        {searchResults.length > 0 && (
          <div className={styles.resultContainer}>
            <h3 className={styles.resultHeader}>Result(s)</h3>
            <ul className={styles.resultList}>
              {searchResults.map((result, index) => (
                <li
                  key={index}
                  onClick={() => handleResultClick(result)}
                  onKeyDown={(event) => handleSearchResultKeyDown(event, result)} 
                  className={styles.resultItem}
                  tabIndex={0}
                >
                 <div>
                    {`${result.GAZETTEER_ENTRY.NAME1} ${result.GAZETTEER_ENTRY.POSTCODE_DISTRICT}`}
                    <div className={styles.resultsXY}>
                      x: {result.GAZETTEER_ENTRY.GEOMETRY_X} y: {result.GAZETTEER_ENTRY.GEOMETRY_Y}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div>
          {errorMessage && (
            <div className={styles.errorMessageOverlay}>{errorMessage}</div>
          )}
          {loading && (
                <div className={styles.loadingOverlay}>
                    <div className={styles.loadingBar}></div>
                </div>
            )}
          <div ref={mapRef} className={styles.mapContainer} tabIndex={0} />
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
