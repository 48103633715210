import './TileCard.css';
import { faMap, faBarChart, faFileCode } from '@fortawesome/free-regular-svg-icons';
import TileCardItem from './TileCardItem';

// TileCard component: A container for displaying multiple TileCardItem components
const TileCard = () => {
    return (
        <div className="tile-card-container">
            <div className="tile-card_box">
                {/* TileCardItem for Map view */}
                <TileCardItem
                    link="/map"
                    icon={faMap}
                    title="Map"
                    description="View collated planning data for England"
                />
                {/* TileCardItem for Dashboards view */}
                <TileCardItem
                    link="/dashboard"
                    icon={faBarChart}
                    title="Dashboards"
                    description="Gain insight into planning data"
                />
                {/* TileCardItem for Data Export view */}
                <TileCardItem
                    link="/data-export"
                    icon={faFileCode}
                    title="Export data"
                    description="Query and export planning data"
                />
            </div>
        </div>
    );
};

export default TileCard;