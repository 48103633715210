// Defined route paths for the application
const ACCESSIBILITY = '/content/accessibility';
const CONTACT = '/content/contact';
const CONTENT_PAGE = '/content/:page';
const COOKIES = '/content/cookies';
const DASHBOARD = '/dashboard';
const DATA_EXPORT = '/data-export';
const HELP = '/content/help';
const HOME = '/';
const MAP = '/map';
const PRIVACY = '/content/privacy';

// Export the route paths for use in App.js, NavBar & Footer components
export { 
    ACCESSIBILITY,
    CONTACT,
    CONTENT_PAGE,
    COOKIES,
    DASHBOARD,
    DATA_EXPORT,
    HELP,
    HOME,
    MAP,
    PRIVACY
};
