import React, { useState } from 'react';
import styles from './Navbar.module.css';
import logo from '../../logo.svg';
import { HOME, MAP, DASHBOARD, DATA_EXPORT } from '../../routes/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';

// Navbar component: A navigation bar with jPlan logo, links, and a hamburger menu for mobile view
const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    // Function to toggle the visibility of the menu in mobile view
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className={styles.navbar} data-testid="navbar">
            <a href={HOME} data-testid="navbar-logo-link">
                <img src={logo} alt="Logo" className={styles.navbarLogo} data-testid="navbar-logo" />
            </a>
            <div className={styles.hamburger} onClick={toggleMenu} data-testid="hamburger-menu">
                <FontAwesomeIcon className={styles.burgerIcon} icon={faBars}/>
            </div>
            <ul className={`${styles.navbarLinks} ${isOpen ? styles.active : ''}`} data-testid="navbar-links">
                <li className={styles.navbarLink}><a href={HOME} data-testid="navbar-link-home">Home</a></li>
                <li className={styles.navbarLink}><a href={MAP} data-testid="navbar-link-map">Map</a></li>
                <li className={styles.navbarLink}><a href={DASHBOARD} data-testid="navbar-link-dashboard">Dashboards</a></li>
                <li className={styles.navbarLink}><a href={DATA_EXPORT} data-testid="navbar-link-export">Export data</a></li>
            </ul>
        </nav>
    );
};

export default Navbar;