import PropTypes from 'prop-types';
import styles from './SearchInput.module.css';

// SearchInput component: A reusable search input field with a button
// Props:
// - inputValue: The current value of the input field
// - handleInputChange: Function to handle changes in the input field
// - handleSearch: Function to handle the search action when the button is clicked
// - placeholder: Placeholder text for the input field
const SearchInput = ({ inputValue, handleInputChange, handleSearch, placeholder }) => {

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className={styles.searchContainer} data-testid="search-container">
      <input 
        type="text" 
        placeholder={placeholder} 
        value={inputValue} 
        onChange={handleInputChange}
        onKeyDown={handleKeyDown} 
        className={styles.searchInput} 
        data-testid="search-input"
      />
      <button 
        className={styles.searchButton} 
        onClick={handleSearch} 
        data-testid="search-button"
      >
        Search
      </button>
    </div>
  );
};

// Prop types validation
SearchInput.propTypes = {
  inputValue: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default SearchInput;
