// Import images and store them in an object with descriptive keys
const images = {
    'home-page.png': require('./images/home-page.png'),
    'click-map-1.png': require('./images/click-map-1.png'),
    'search-location-2.png': require('./images/search-location-2.png'),
    'enter-location-3.png': require('./images/enter-location-3.png'),
    'click-desired-location-4.png': require('./images/click-desired-location-4.png'),
    'drop-pin-5.png': require('./images/drop-pin-5.png'),
    'expand-data-6.png': require('./images/expand-data-6.png'),
    'toggle-checkbox-7.png': require('./images/toggle-checkbox-7.png'),
    'zoom-location-8.png': require('./images/zoom-location-8.png'),
    'toggle-another-layer-9.png': require('./images/toggle-another-layer-9.png'),
    'click-expand-10.png': require('./images/click-expand-10.png'),
    'collapse-panel-11.png': require('./images/collapse-panel-11.png'),
    'clear-button-12.png': require('./images/clear-button-12.png'),
    'data-removed-13.png': require('./images/data-removed-13.png'),
  };
  
  export default images;