import React from 'react';
import Page from '../Page/Page';
import TileCard from '../TileCard/TileCard';
import styles from './HomePage.module.css';

// HomePage component: The main landing page of the application
// Displays a welcome message, a TileCard component, and information about jPlan
const HomePage = () => {
  return (
    <Page>
        <h1 className={styles.title}>Welcome to jPlan</h1>
        <TileCard />
        <div className={styles.contentContainer}>
        <h2>About jPlan</h2>
        <p >
          jPlan is designed to simplify access to England's planning data, 
          empowering users to make more informed and efficient decisions in the property planning sector.
          Our mission is to provide a platform that makes essential data easier to find, use, and trust.
          By fostering transparency and accessibility to planning data,
          jPlan aims to improve planning outcomes and strengthen relationships between communities and developers.
        </p>
        <h2>Core features</h2>
          <ul>
            <li>Interactive Map with England Planning Data</li>
            <li>Data Dashboards</li>
            <li>Data Export Functionality</li>
          </ul>
        </div>
  </Page>
  );
};

export default HomePage;