import { useState, useEffect } from 'react';
import Page from '../Page/Page';
import { getPlanningData } from '../../Services/apiServices';
import { formatDatasetName } from '../../utils/FormatDataSetName';
import SearchInput from '../SearchInput/SearchInput';
import styles from './DataExportPage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleDown, faFileCode } from '@fortawesome/free-regular-svg-icons';
import LoadingIcon from '../LoadingIcon/LoadingIcon';

// DataExportPage component: allows users to query and export planning data taht's displayed in a table
const DataExportPage = () => {
  const [inputValue, setInputValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadData = async () => {
        setLoading(true);
        const params = { limit: 10, offset: (page - 1) * 10 };
        if (searchTerm) {
          params.reference = searchTerm;
        }
        const response = await getPlanningData(params);
        setData(response.entities);
        setTotalPages(Math.ceil(response.count / 10));
        setLoading(false);
    };
    loadData();
  }, [page, searchTerm]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  
  const handleSearch = () => {
    setSearchTerm(inputValue);
  };

  const generateExportUrl = () => {
    const params = new URLSearchParams({ limit: 10, offset: (page - 1) * 10 });
    if (searchTerm) {
      params.append('reference', searchTerm);
    }
    return `https://www.planning.data.gov.uk/entity.json?${params.toString()}`;
  };
  
  return (
    <Page>
    <h1 className={styles.title} data-testid="export-title">Export planning data</h1>
    {loading ? (
      <LoadingIcon/>
    ) : (
      <>
        <div className={styles.actionBar} data-testid="action-bar">
          <SearchInput 
            inputValue={inputValue} 
            handleInputChange={handleInputChange} 
            handleSearch={handleSearch} 
            placeholder="Search by reference" 
          />
          <div className={styles.exportButtonContainer}>
            <a 
              className={styles.exportButton} 
              href={generateExportUrl()} 
              target="_blank" 
              rel="noopener noreferrer" 
              data-testid="export-button"
            >
              <FontAwesomeIcon className={styles.downloadIcon} icon={faFileCode} data-testid="download-icon" />
              Export results to JSON
            </a>
          </div>
        </div>
        <div className={styles.tableContainer} data-testid="table-container">
          <table className={styles.centeredTable} data-testid="centered-table">
            <thead>
              <tr>
                <th data-testid="entry-date-header">Entry date</th>
                <th data-testid="start-date-header">Start date</th>
                <th data-testid="end-date-header">End date</th>
                <th data-testid="name-header">Name</th>
                <th data-testid="dataset-header">Dataset</th>
                <th data-testid="typology">Typology</th>
                <th data-testid="reference">Reference</th>
                <th data-testid="download">Download</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{item['entry-date'] ? new Date(item['entry-date']).toLocaleDateString('en-GB') : 'N/A'}</td>
                  <td>{item['start-date'] ? new Date(item['start-date']).toLocaleDateString('en-GB') : 'N/A'}</td>
                  <td>{item['end-date'] ? new Date(item['end-date']).toLocaleDateString('en-GB') : 'N/A'}</td>
                  <td>{item.name}</td>
                  <td>{formatDatasetName(item?.dataset)}</td>
                  <td>{item.typology}</td>
                  <td>{item.reference}</td>
                  <td>
                    <a href={`https://files.planning.data.gov.uk/dataset/${item.dataset}.csv`} target="_blank" rel="noopener noreferrer" title={`Download ${item.dataset} dataset`}>
                      <FontAwesomeIcon className={styles.downloadIcon} icon={faArrowAltCircleDown} />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.pagination}>
          <button 
            className={styles.paginationButton} 
            onClick={() => handlePageChange(1)} 
            disabled={page === 1}
          >
            First
          </button>
          <button 
            className={styles.paginationButton} 
            onClick={() => handlePageChange(page - 1)} 
            disabled={page === 1}
          >
            Previous
          </button>
          <span 
            className={styles.paginationPageCount}
          >
            Page {page} of {totalPages}
          </span>
          <button 
            className={styles.paginationButton} 
            onClick={() => handlePageChange(page + 1)} 
            disabled={page === totalPages}
          >
            Next
          </button>
          <button 
            className={styles.paginationButton} 
            onClick={() => handlePageChange(totalPages)} 
            disabled={page === totalPages}
          >
            Last
          </button>
        </div>
      </>
    )}
  </Page>
  );
};

export default DataExportPage;
