import React from 'react';
import styles from './Footer.module.css';
import { HELP, PRIVACY, ACCESSIBILITY, COOKIES, CONTACT } from '../../routes/routes';

// Footer component: A footer section that includes links to standard footer link pages
const Footer = () => {
    const links = [
        { text: 'Help', href: HELP },
        { text: 'Privacy', href: PRIVACY },
        { text: 'Accessibility', href: ACCESSIBILITY },
        { text: 'Cookies', href: COOKIES },
        { text: 'Contact', href: CONTACT }
      ];

      return (
        <footer className={styles.footer} data-testid="footer" >
        <ul className={styles.footerLinks} data-testid="footer-links">
          {links.map((link, index) => (
            <li key={index} className={styles.footerLinkItem} data-testid={`footer-link-item-${index}`}>
              <a href={link.href} className={styles.footerLink} data-testid={`footer-link-${index}`}>{link.text}</a>
            </li>
          ))}
        </ul>
      </footer>
      );
    };

export default Footer;