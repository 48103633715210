import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Page from '../Page/Page';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styles from './ContentPage.module.css';
import images from '../../markdown/imageImports';

// Dynamically require markdown files from the markdown directory
const markdownContext = require.context('../../markdown', false, /\.md$/);

// ContentPage component: A page component that dynamically loads and displays markdown content
const ContentPage = () => {
  const { page } = useParams();
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchMarkdown = async () => {
      try {
        const markdownFile = markdownContext(`./${page}.md`);
        const response = await fetch(markdownFile);
        let text = await response.text();

        // Replace image paths with imported images
        Object.keys(images).forEach((key) => {
          const imagePath = `./images/${key}`;
          text = text.replace(imagePath, images[key]);
        });

        setContent(text);
      } catch (error) {
        setContent('# Page not found');
      }
    };

    fetchMarkdown();
  }, [page]);

  return (
    <Page>
        <div className={styles.contentContainer} data-testid="markdown-content">
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
        </div>
    </Page>
  );
};

export default ContentPage;
