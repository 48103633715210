import { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { getPlanningData } from '../../Services/apiServices';
import Page from '../Page/Page';
import LoadingIcon from '../LoadingIcon/LoadingIcon';
import typologyNames from '../../config/typologyNames';
import datasetNames from '../../config/datasetNames';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './Dashboard.module.css';
import { formatDatasetName } from '../../utils/FormatDataSetName';

// Dashboard component: displays entities metric, dataset distribution, timeline, and dot distribution map
const Dashboard = () => {
    const chartRef = useRef();
    const [datasetSelected, setDatasetSelected] = useState('');
    const [typologySelected, setTypologySelected] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(null);
    const [activeEntities, setActiveEntities] = useState(0);
    const [data, setData] = useState([]);
    const [points, setPoints] = useState([]);

    const handleTypologyChange = (event) => {
        setTypologySelected(event.target.value);
    };

    const handleDatasetChange = (event) => {
        setDatasetSelected(event.target.value);
    };

    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            const params = { limit: 500 };
            if (typologySelected) {
                params.typology = typologySelected;
            }
            if (datasetSelected) {
                params.dataset = datasetSelected;
            }
            if (startDate) {
                params.start_date_year = startDate.getFullYear();
                params.start_date_month = startDate.getMonth() + 1;
                params.start_date_day = startDate.getDate();
            }
            try {
                const response = await getPlanningData(params);
                setData(response.entities);
                setCount(response.count);
                setActiveEntities(response.entities.filter(e => !e['end-date']).length);

                    // Extract points for the heatmap
            const points = response.entities.map(entity => {
                const match = entity.point.match(/POINT \(([^ ]+) ([^ ]+)\)/);
                return match ? [parseFloat(match[1]), parseFloat(match[2])] : null;
            }).filter(point => point !== null);
            setPoints(points);


            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        loadData();
    }, [typologySelected, datasetSelected, startDate]);

    useEffect(() => {
        if (!data.length || loading) return;
        const margin = { top: 40, right: 30, bottom: 100, left: 60 };
        const width = 1000 - margin.left - margin.right;
        const height = 800 - margin.top - margin.bottom;

        // Clear existing content
        const chartContainer = d3.select(chartRef.current);
        chartContainer.selectAll('*').remove();

        // 1. Dataset Distribution Pie Chart
        const createPieChart = () => {
          const radius = 80;
          
          const datasetCounts = data.reduce((acc, curr) => {
              acc[curr.dataset] = (acc[curr.dataset] || 0) + 1;
              return acc;
          }, {});
      
          const pieData = Object.entries(datasetCounts)
              .map(([name, value]) => ({ name, value }))
              .sort((a, b) => b.value - a.value);
      
          const pie = d3.pie()
              .value(d => d.value)
              .sort(null);
      
          const arc = d3.arc()
              .innerRadius(0)
              .outerRadius(radius);
      
          // Create arc for the leader lines
          const outerArc = d3.arc()
              .innerRadius(radius * 1.1)
              .outerRadius(radius * 1.1);
      
          const svg = chartContainer
              .append('svg')
              .attr('width', width)
              .attr('height', height)
              .append('g')
              .attr('transform', `translate(${width / 2},${height / 2})`); // Center the pie chart
      
          // Add title
          svg.append('text')
              .attr('x', 0)
              .attr('y', -height / 2 + 20)
              .attr('text-anchor', 'middle')
              .style('font-size', '16px')
              .style('font-weight', 'bold')
              .text('Dataset Distribution');
      
          // Create pie slices
          // eslint-disable-next-line
          const slices = svg.selectAll('path')
              .data(pie(pieData))
              .enter()
              .append('path')
              .attr('d', arc)
              .attr('fill', (d, i) => d3.schemeCategory10[i % 10])
              .on('mouseover', function(event, d) {
                  d3.select(this)
                      .transition()
                      .duration(200)
                      .attr('opacity', 0.7);
                  
                  d3.select(`#label-${d.data.name.replace(/[^a-zA-Z0-9]/g, '_')}`)
                      .style('font-weight', 'bold');
              })
              .on('mouseout', function(event, d) {
                  d3.select(this)
                      .transition()
                      .duration(200)
                      .attr('opacity', 1);
                  
                  d3.select(`#label-${d.data.name.replace(/[^a-zA-Z0-9]/g, '_')}`)
                      .style('font-weight', 'normal');
              });
      
          // Add labels with improved spacing
          const labelGroups = svg.selectAll('.label-group')
              .data(pie(pieData))
              .enter()
              .append('g')
              .attr('class', 'label-group');
      
          // Function to determine if label should be on left or right
          const rightSide = d => (d.startAngle + d.endAngle) / 2 < Math.PI;
      
          // Function to handle label overlap
          const getLabelY = (d, i) => {
              const baseY = outerArc.centroid(d)[1];
              const side = rightSide(d);
              const sign = side ? 1 : -1;
              // Stagger labels vertically based on index
              return baseY + (i * 20 * sign);
          };
      
          // Add the polylines with improved positioning
          labelGroups.append('polyline')
              .attr('points', function(d, i) {
                  const posA = arc.centroid(d);
                  const posB = outerArc.centroid(d);
                  const posC = [
                      radius * 1.5 * (rightSide(d) ? 1 : -1),
                      getLabelY(d, i)
                  ];
                  return [posA, posB, posC];
              })
              .style('fill', 'none')
              .style('stroke', '#666')
              .style('stroke-width', '1px');
      
          // Add the labels with improved positioning
          labelGroups.append('text')
              .attr('id', d => `label-${d.data.name.replace(/[^a-zA-Z0-9]/g, '_')}`)
              .each(function(d, i) {
                  const text = d3.select(this);
                  const percent = ((d.value / d3.sum(pieData, d => d.value)) * 100).toFixed(1);
                  const side = rightSide(d);
                  
                  // Position the text with improved spacing
                  const x = radius * 1.6 * (side ? 1 : -1);
                  const y = getLabelY(d, i);
                  text.attr('transform', `translate(${x},${y})`);
                  
                  // Add the label text
                  text.append('tspan')
                      .style('font-size', '12px')
                      .style('text-anchor', side ? 'start' : 'end')
                      .text(`${formatDatasetName(d.data.name)}`);
                  
                  // Add the percentage on a new line
                  text.append('tspan')
                      .style('font-size', '10px')
                      .style('fill', '#666')
                      .style('text-anchor', side ? 'start' : 'end')
                      .attr('x', 0)
                      .attr('dy', '1.2em')
                      .text(`${d.value} (${percent}%)`);
              });
      };

        // 2. Timeline Bar Chart
        const createTimelineChart = () => {
            const timeData = data.reduce((acc, curr) => {
                const year = new Date(curr['start-date']).getFullYear();
                if (!isNaN(year)) {  // Check for valid dates
                    acc[year] = (acc[year] || 0) + 1;
                }
                return acc;
            }, {});

            const timelineData = Object.entries(timeData)
                .map(([year, count]) => ({ year: +year, count }))
                .sort((a, b) => a.year - b.year);

            if (!timelineData.length) return;  // Don't create chart if no valid data

            const svg = chartContainer
                .append('svg')
                .attr('width', width)
                .attr('height', height + margin.top + margin.bottom)
                .append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`);

            // Add title
            svg.append('text')
                .attr('x', width / 2)
                .attr('y', -margin.top / 2)
                .attr('text-anchor', 'middle')
                .style('font-size', '16px')
                .style('font-weight', 'bold')
                .text('Entities Timeline');

            // Create scales
            const x = d3.scaleBand()
                .range([0, width - margin.left - margin.right])
                .domain(timelineData.map(d => d.year))
                .padding(0.1);

            const y = d3.scaleLinear()
                .range([height, 0])
                .domain([0, d3.max(timelineData, d => d.count)]);

            // Add axes
            svg.append('g')
                .attr('transform', `translate(0,${height})`)
                .call(d3.axisBottom(x))
                .selectAll('text')
                .style('text-anchor', 'end')
                .attr('dx', '-.8em')
                .attr('dy', '.15em')
                .attr('transform', 'rotate(-45)');

            svg.append('g')
                .call(d3.axisLeft(y));

            // Add bars with tooltips
            const tooltip = d3.select(chartRef.current)
                .append('div')
                .attr('class', styles.tooltip)
                .style('opacity', 0);

            svg.selectAll('rect')
                .data(timelineData)
                .enter()
                .append('rect')
                .attr('x', d => x(d.year))
                .attr('y', d => y(d.count))
                .attr('width', x.bandwidth())
                .attr('height', d => height - y(d.count))
                .attr('fill', '#4f46e5')
                .on('mouseover', function(event, d) {
                    d3.select(this)
                        .transition()
                        .duration(200)
                        .attr('fill', '#818cf8');
                    
                    tooltip.transition()
                        .duration(200)
                        .style('opacity', .9);
                    
                    tooltip.html(`Year: ${d.year}<br/>Count: ${d.count}`)
                        .style('left', (event.pageX + 10) + 'px')
                        .style('top', (event.pageY - 28) + 'px');
                })
                .on('mouseout', function(event, d) {
                    d3.select(this)
                        .transition()
                        .duration(200)
                        .attr('fill', '#4f46e5');
                    
                    tooltip.transition()
                        .duration(500)
                        .style('opacity', 0);
                });
        };

        const createDotDensityMap = (points) => {
            const width = 800;
            const height = 600;
            const margin = { top: 20, right: 20, bottom: 20, left: 20 };
        
            // Clear any existing SVG
            d3.select("#heatmap").selectAll("*").remove();
        
            const svg = d3.select("#heatmap")
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);

                svg.append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr("fill", "lightblue");
        
            // Define projection for UK
            const projection = d3.geoMercator()
                .center([-2, 54.5]) // Centered on UK
                .scale(2500)
                .translate([width / 2, height / 2]);
        
            const path = d3.geoPath().projection(projection);
        
            fetch('https://raw.githubusercontent.com/martinjc/UK-GeoJSON/master/json/administrative/gb/lad.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(ukGeoJson => {
                const uk = ukGeoJson;
                
                svg.append("g")
                    .selectAll("path")
                    .data(uk.features)
                    .enter()
                    .append("path")
                    .attr("d", path)
                    .attr("fill", "#e5e5e5")
                    .attr("stroke", "#fff")
                    .attr("stroke-width", 0.5);
        
                // Add points on top of the map
                svg.selectAll("circle")
                    .data(points)
                    .enter()
                    .append("circle")
                    .attr("cx", d => projection(d)[0])
                    .attr("cy", d => projection(d)[1])
                    .attr("r", 5)
                    .attr("fill", "red")
                    .attr("opacity", 0.5)
                    .attr("stroke", "#fff")
                    .attr("stroke-width", 0.5);
        
                // Add zoom capabilities
                const zoom = d3.zoom()
                    .scaleExtent([1, 8])
                    .on('zoom', (event) => {
                        svg.selectAll('path')
                            .attr('transform', event.transform);
                        svg.selectAll('circle')
                            .attr('transform', event.transform)
                            .attr('r', 5 / event.transform.k);
                    });
        
                    svg.call(zoom);
                })
                .catch(error => {
                    svg.append("text")
                        .attr("x", 50)
                        .attr("y", 50)
                        .attr("fill", "red")
                        .text(`Error fetching data: ${error.message}`);
            });
        };

        // Render all charts
        if (data.length > 0) {
            createPieChart();
            createTimelineChart();
            createDotDensityMap(points);
        }

        // Cleanup function
        return () => {
            chartContainer.selectAll('*').remove();
        };
    }, [data, loading, points]);

    return (
        <Page>
            {loading ? (
                <LoadingIcon />
            ) : (
                <>
                    <h1 className={styles.dashboardTitle}>Dashboard</h1>
                    <div className={styles.searchFilterContainer}>
                        <div className={styles.filtersContainer}>
                            <label htmlFor="typology-select">Select Typology</label>
                            <select id="typology-select" value={typologySelected} onChange={handleTypologyChange}>
                                <option value="">Please choose an option</option>
                                {typologyNames.typology_names.map((typology, index) => (
                                    <option key={index} value={typology}>
                                        {formatDatasetName(typology)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className={styles.filtersContainer}>
                            <label htmlFor="dataset-select">Select Dataset</label>
                            <select id="dataset-select" value={datasetSelected} onChange={handleDatasetChange}>
                                <option value="">Please choose an option</option>
                                {datasetNames.dataset_names.map((dataset, index) => (
                                    <option key={index} value={dataset}>
                                        {formatDatasetName(dataset)}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <DatePicker
                            className={styles.datePicker}
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="yyyy/MM/dd"
                            placeholderText="Select a start date"
                        />
                    </div>
                    <div className={styles.statsContainer}>
                        <div className={styles.card}>
                            <div className={styles.cardContent}>
                                {count === 0 ? (
                                    <p className={styles.noData}>No data found for given search criteria, please check and try again.</p>
                                ) : (
                                    <>
                                        <h2>Total Entities</h2>
                                        <p>{count}</p>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={styles.card}>
                            <div className={styles.cardContent}>
                                <h2>Active Entities</h2>
                                {count > 500 ? (
                                    <p>{activeEntities} (out of 500 returned entities)</p>
                                ) : (
                                    <p>{activeEntities}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div ref={chartRef} className={styles.chartsContainer}/>
                    <h2 className={styles.dashboardTitle}>Dot distribution map</h2>
                    <div id="heatmap"  className={styles.dotDistributionMap}/>
                </>
            )}
        </Page>
    );
};

export default Dashboard;