import styles from './LoadingIcon.module.css';

// LoadingIcon component: A visual indicator to show that content is loading
const LoadingIcon = () => {
  return (
    <div className={styles.loaderContainer} data-testid="loading-icon">
      <div className={styles.loader}></div>
      <div className={styles.loaderText}>Loading...</div>
    </div>
  );
};

export default LoadingIcon;