const colourConfig = {
    'address': 'rgba(42, 41, 41, 0.4)',
    'agricultural-land-classification': 'rgba(34, 139, 34, 0.4)',
    'ancient-woodland': 'rgba(34, 139, 34, 0.5)',
    'ancient-woodland-status': 'rgba(34, 139, 34, 0.6)',
    'area-of-outstanding-natural-beauty': 'rgba(34, 139, 34, 0.7)',
    'article-4-direction': 'rgba(255, 69, 0, 0.4)',
    'article-4-direction-area': 'rgba(255, 69, 0, 0.5)',
    'article-4-direction-rule': 'rgba(255, 69, 0, 0.6)',
    'asset-of-community-value': 'rgba(255, 69, 0, 0.7)',
    'attribution': 'rgba(255, 69, 0, 0.8)',
    'battlefield': 'rgba(139, 0, 0, 0.4)',
    'best-and-most-versatile-agricultural-land': 'rgba(34, 139, 34, 0.8)',
    'biodiversity-net-gain-assessment': 'rgba(34, 139, 34, 0.9)',
    'border': 'rgba(37, 180, 232, 0.2)',
    'brownfield-land': 'rgba(160, 82, 45, 0.4)',
    'brownfield-site': 'rgba(160, 82, 45, 0.5)',
    'buffer-zone': 'rgba(160, 82, 45, 0.6)',
    'building-preservation-notice': 'rgba(160, 82, 45, 0.7)',
    'built-up-area': 'rgba(160, 82, 45, 0.8)',
    'category': 'rgba(255, 215, 0, 0.4)',
    'central-activities-zone': 'rgba(255, 215, 0, 0.5)',
    'certificate-of-immunity': 'rgba(255, 215, 0, 0.6)',
    'coastal-change-management-area': 'rgba(70, 130, 180, 0.4)',
    'column': 'rgba(70, 130, 180, 0.5)',
    'common-land-and-village-green': 'rgba(70, 130, 180, 0.6)',
    'community-infrastructure-levy-schedule': 'rgba(70, 130, 180, 0.7)',
    'company': 'rgba(70, 130, 180, 0.8)',
    'conservation-area': 'rgba(34, 139, 34, 1.0)',
    'conservation-area-document': 'rgba(34, 139, 34, 0.9)',
    'conservation-area-document-type': 'rgba(34, 139, 34, 0.8)',
    'contaminated-land': 'rgba(255, 0, 0, 0.4)',
    'contribution-funding-status': 'rgba(255, 0, 0, 0.5)',
    'contribution-purpose': 'rgba(255, 0, 0, 0.6)',
    'control-of-major-accident-hazards-site': 'rgba(255, 0, 0, 0.7)',
    'default': 'rgba(0, 0, 0, 0.4)',
    'default-value': 'rgba(0, 0, 0, 0.5)',
    'design-code': 'rgba(0, 128, 128, 0.4)',
    'design-code-area': 'rgba(0, 128, 128, 0.5)',
    'design-code-area-type': 'rgba(0, 128, 128, 0.6)',
    'design-code-characteristic': 'rgba(0, 128, 128, 0.7)',
    'design-code-rule': 'rgba(0, 128, 128, 0.8)',
    'design-code-rule-category': 'rgba(0, 128, 128, 0.9)',
    'design-code-status': 'rgba(0, 128, 128, 1.0)',
    'developer-agreement': 'rgba(255, 140, 0, 0.4)',
    'developer-agreement-contribution': 'rgba(255, 140, 0, 0.4)',
    'developer-agreement-transaction': 'rgba(255, 140, 0, 0.5)',
    'developer-agreement-type': 'rgba(255, 140, 0, 0.6)',
    'development-corporation': 'rgba(255, 69, 0, 0.4)',
    'development-metric': 'rgba(255, 69, 0, 0.5)',
    'development-plan': 'rgba(255, 69, 0, 0.6)',
    'development-plan-boundary': 'rgba(255, 69, 0, 0.7)',
    'development-plan-boundary-type': 'rgba(255, 69, 0, 0.8)',
    'development-plan-document': 'rgba(255, 69, 0, 0.9)',
    'development-plan-document-type': 'rgba(255, 69, 0, 1.0)',
    'development-plan-event': 'rgba(255, 69, 0, 0.4)',
    'development-plan-geography': 'rgba(255, 69, 0, 0.5)',
    'development-plan-geography-type': 'rgba(255, 69, 0, 0.6)',
    'development-plan-status': 'rgba(255, 69, 0, 0.7)',
    'development-plan-timetable': 'rgba(255, 69, 0, 0.8)',
    'development-plan-type': 'rgba(255, 69, 0, 0.9)',
    'development-policy': 'rgba(255, 69, 0, 1.0)',
    'development-policy-area': 'rgba(255, 69, 0, 0.4)',
    'development-policy-category': 'rgba(255, 69, 0, 0.5)',
    'development-policy-metric': 'rgba(255, 69, 0, 0.6)',
    'document': 'rgba(70, 130, 180, 0.4)',
    'document-type': 'rgba(70, 130, 180, 0.5)',
    'educational-establishment': 'rgba(70, 130, 180, 0.6)',
    'employment-allocation': 'rgba(70, 130, 180, 0.7)',
    'entity': 'rgba(70, 130, 180, 0.8)',
    'entity-organisation': 'rgba(70, 130, 180, 0.9)',
    'expect': 'rgba(70, 130, 180, 1.0)',
    'expectation': 'rgba(70, 130, 180, 0.4)',
    'fact': 'rgba(70, 130, 180, 0.5)',
    'fact-resource': 'rgba(70, 130, 180, 0.6)',
    'flood-risk-level': 'rgba(68, 118, 227, 0.4)',
    'flood-risk-type': 'rgba(8, 118, 227, 0.5)',
    'flood-risk-zone': 'rgba(8, 118, 227, 0.6)',
    'flood-storage-area': 'rgba(8, 118, 227, 0.7)',
    'forest-inventory': 'rgba(34, 139, 34, 0.8)',
    'government-organisation': 'rgba(34, 139, 34, 0.9)',
    'green-belt': 'rgba(34, 139, 34, 1.0)',
    'green-belt-core': 'rgba(34, 139, 34, 0.4)',
    'guardianship-site-and-english-heritage-site': 'rgba(34, 139, 34, 0.5)',
    'gypsy-and-traveller-site': 'rgba(34, 139, 34, 0.6)',
    'heritage-action-zone': 'rgba(34, 139, 34, 0.7)',
    'heritage-at-risk': 'rgba(34, 139, 34, 0.8)',
    'heritage-coast': 'rgba(34, 139, 34, 0.9)',
    'historic-non-designed-rural-landscape-local-landscape-area': 'rgba(34, 139, 34, 1.0)',
    'historic-stone-quarry': 'rgba(34, 139, 34, 0.4)',
    'housing-allocation': 'rgba(34, 139, 34, 0.5)',
    'hs2-safeguarded-area': 'rgba(34, 139, 34, 0.6)',
    'infrastructure-funding-statement': 'rgba(34, 139, 34, 0.7)',
    'infrastructure-project': 'rgba(34, 139, 34, 0.8)',
    'infrastructure-project-decision': 'rgba(34, 139, 34, 0.9)',
    'infrastructure-project-document': 'rgba(34, 139, 34, 1.0)',
    'infrastructure-project-document-type': 'rgba(34, 139, 34, 0.4)',
    'infrastructure-project-event': 'rgba(34, 139, 34, 0.5)',
    'infrastructure-project-log': 'rgba(34, 139, 34, 0.6)',
    'infrastructure-project-type': 'rgba(34, 139, 34, 0.7)',
    'internal-drainage-board': 'rgba(34, 139, 34, 0.8)',
    'internal-drainage-district': 'rgba(34, 139, 34, 0.9)',
    'licence': 'rgba(34, 139, 34, 1.0)',
    'listed-building': 'rgba(34, 139, 34, 0.4)',
    'listed-building-grade': 'rgba(34, 139, 34, 0.5)',
    'listed-building-outline': 'rgba(34, 139, 34, 0.6)',
    'local-area-requirements': 'rgba(34, 139, 34, 0.7)',
    'local-authority': 'rgba(34, 139, 34, 0.8)',
    'local-authority-district': 'rgba(34, 139, 34, 0.9)',
    'local-authority-type': 'rgba(34, 139, 34, 1.0)',
    'local-enterprise-partnership': 'rgba(34, 139, 34, 0.4)',
    'local-green-space': 'rgba(34, 139, 34, 0.5)',
    'local-nature-recovery-strategy': 'rgba(34, 139, 34, 0.6)',
    'local-nature-reserve': 'rgba(34, 139, 34, 0.7)',
    'local-plan': 'rgba(238, 67, 173, 0.8)',
    'local-plan-boundary': 'rgba(238, 67, 173, 0.9)',
    'local-plan-document': 'rgba(238, 67, 173, 1.0)',
    'local-plan-document-type': 'rgba(238, 67, 173, 0.4)',
    'local-plan-event': 'rgba(238, 67, 173, 0.5)',
    'local-plan-timetable': 'rgba(238, 67, 173, 0.6)',
    'local-planning-authority': 'rgba(238, 67, 173, 0.7)',
    'local-resilience-forum': 'rgba(34, 139, 34, 0.8)',
    'local-resilience-forum-boundary': 'rgba(34, 139, 34, 0.9)',
    'locally-listed-building': 'rgba(34, 139, 34, 1.0)',
    'london-square': 'rgba(34, 139, 34, 0.4)',
    'long-established-woodland': 'rgba(34, 139, 34, 0.5)',
    'long-protected-woodland': 'rgba(34, 139, 34, 0.6)',
    'main-river': 'rgba(34, 139, 34, 0.7)',
    'metropolitan-open-land': 'rgba(34, 139, 34, 0.8)',
    'mineral-safeguarding-area': 'rgba(34, 139, 34, 0.9)',
    'national-nature-reserve': 'rgba(34, 139, 34, 1.0)',
    'national-park': 'rgba(34, 139, 34, 0.4)',
    'national-park-authority': 'rgba(34, 139, 34, 0.5)',
    'nature-improvement-area': 'rgba(34, 139, 34, 0.6)',
    'neighbourhood-forum': 'rgba(34, 139, 34, 0.7)',
    'non-designated-and-locally-listed-historic-asset': 'rgba(34, 139, 34, 0.8)',
    'non-designated-archeology-asset-of-national-importance': 'rgba(34, 139, 34, 0.9)',
    'nonprofit': 'rgba(34, 139, 34, 1.0)',
    'nuclear-safety-zone': 'rgba(34, 139, 34, 0.4)',
    'old-entity': 'rgba(34, 139, 34, 0.5)',
    'old-resource': 'rgba(34, 139, 34, 0.6)',
    'open-space': 'rgba(34, 139, 34, 0.7)',
    'ownership-status': 'rgba(34, 139, 34, 0.8)',
    'parish': 'rgba(34, 139, 34, 0.9)',
    'park-and-garden': 'rgba(34, 139, 34, 1.0)',
    'park-and-garden-grade': 'rgba(34, 139, 34, 0.4)',
    'passenger-transport-executive': 'rgba(34, 139, 34, 0.5)',
    'permitted-development-right': 'rgba(34, 139, 34, 0.6)',
    'permitted-development-right-part': 'rgba(34, 139, 34, 0.7)',
    'phase': 'rgba(34, 139, 34, 0.8)',
    'planning-application-category': 'rgba(34, 139, 34, 0.9)',
    'planning-application-condition': 'rgba(34, 139, 34, 1.0)',
    'planning-application-decision': 'rgba(34, 139, 34, 0.4)',
    'planning-application-document': 'rgba(34, 139, 34, 0.5)',
    'planning-application-log': 'rgba(34, 139, 34, 0.6)',
    'planning-application-status': 'rgba(34, 139, 34, 0.7)',
    'planning-application-type': 'rgba(34, 139, 34, 0.8)',
    'planning-condition': 'rgba(34, 139, 34, 0.9)',
    'planning-condition-purpose': 'rgba(34, 139, 34, 1.0)',
    'planning-condition-target': 'rgba(34, 139, 34, 0.4)',
    'planning-condition-type': 'rgba(34, 139, 34, 0.5)',
    'planning-decision': 'rgba(34, 139, 34, 0.6)',
    'planning-decision-type': 'rgba(34, 139, 34, 0.7)',
    'planning-development-category': 'rgba(34, 139, 34, 0.8)',
    'planning-permission-status': 'rgba(34, 139, 34, 0.9)',
    'planning-permission-type': 'rgba(34, 139, 34, 1.0)',
    'policy': 'rgba(34, 139, 34, 0.4)',
    'prefix': 'rgba(34, 139, 34, 0.5)',
    'proposed-ramsar-site': 'rgba(34, 139, 34, 0.6)',
    'protected-land': 'rgba(34, 139, 34, 0.7)',
    'protected-view': 'rgba(34, 139, 34, 0.8)',
    'protected-wreck-site': 'rgba(34, 139, 34, 0.9)',
    'public-authority': 'rgba(34, 139, 34, 1.0)',
    'public-safety-zone-around-airport': 'rgba(34, 139, 34, 0.4)',
    'ramsar': 'rgba(34, 139, 34, 0.5)',
    'realm': 'rgba(34, 139, 34, 0.6)',
    'region': 'rgba(34, 139, 34, 0.7)',
    'regional-park-authority': 'rgba(34, 139, 34, 0.8)',
    'safeguarded-aerodrome': 'rgba(34, 139, 34, 0.9)',
    'safeguarded-military-explosives-site': 'rgba(34, 139, 34, 1.0)',
    'safeguarded-wharf': 'rgba(34, 139, 34, 0.4)',
    'safety-hazard-area': 'rgba(34, 139, 34, 0.5)',
    'scheduled-monument': 'rgba(34, 139, 34, 0.6)',
    'self-and-custom-buildarea': 'rgba(34, 139, 34, 0.7)',
    'site-category': 'rgba(34, 139, 34, 0.8)',
    'site-of-special-scientific-interest': 'rgba(34, 139, 34, 0.9)',
    'special-area-of-conservation': 'rgba(34, 139, 34, 1.0)',
    'special-protection-area': 'rgba(34, 139, 34, 0.4)',
    'street': 'rgba(34, 139, 34, 0.5)',
    'suitable-alternative-green-space': 'rgba(34, 139, 34, 0.6)',
    'title-boundary': 'rgba(34, 139, 34, 0.7)',
    'transport-access-node': 'rgba(34, 139, 34, 0.8)',
    'transport-under-tcpa-route': 'rgba(34, 139, 34, 0.9)',
    'tree': 'rgba(34, 139, 34, 1.0)',
    'tree-preservation-order': 'rgba(34, 139, 34, 0.4)',
    'tree-preservation-zone': 'rgba(34, 139, 34, 0.5)',
    'tree-preservation-zone-type': 'rgba(34, 139, 34, 0.6)',
    'uprn': 'rgba(34, 139, 34, 0.7)',
    'ward': 'rgba(34, 139, 34, 0.8)',
    'waste-authority': 'rgba(34, 139, 34, 0.9)',
    'wildbelt': 'rgba(34, 139, 34, 1.0)',
    'wildlife': 'rgba(34, 139, 34, 0.4)',
    'world-heritage-site': 'rgba(34, 139, 34, 0.5)',
    'world-heritage-site-buffer-zone': 'rgba(34, 139, 34, 0.6)',
  };
  export default colourConfig;