const typologyNames = {
    "typology_names": [
      "category",
      "document",
      "entity",
      "geography",
      "legal-instrument",
      "metric",
      "organisation",
      "pipeline",
      "policy",
      "specification",
      "timetable",
      "value"
    ]
  };

  export default typologyNames;