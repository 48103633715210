import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import styles from './Page.module.css';

// Page component: A layout component that includes a Navbar at the top, a Footer at the bottom, and a content area in between
// Props:
// - children: The content to be displayed within the page
const Page = ({ children }) => {
  return (
    <div className={styles.pageContainer} data-testid="page-container">
      <Navbar/>
      <div className={styles.pageContent} data-testid="page-content">
        {children}
      </div>
      <Footer/>
    </div>
  );
};

export default Page;