import Page from '../Page/Page';
import MapComponent from '../Map/Map'; 

// MapPage component: A page component that displays the map component in the standard page layout
const MapPage = () => {
  return (
    <Page>
      <MapComponent />
    </Page>
  );
};

export default MapPage;