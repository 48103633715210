import React from 'react';
import styles from './ClearButton.module.css';

// ClearButton component: A button component that triggers a clear action, such as clearing a pin on the map
// Props:
// - onClick: Function to handle the button click event
// - buttonText: Text to display on the button
const ClearButton = ({ onClick, buttonText }) => {
  return (
    <button className={styles.button} onClick={onClick} data-testid="clear-button">
      <span className={styles.text}>{buttonText}</span>
      <span className={styles.icon}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
        </svg>
      </span>
    </button>
  );
};

export default ClearButton;
