import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage/HomePage';
import MapPage from './components/MapPage/MapPage'; 
import Dashboard from './components/Dashboard/Dashboard';
import DataExportPage from './components/DataExport/DataExportPage';
import ContentPage from './components/ContentPage/ContentPage';
import { HOME, MAP, DASHBOARD, DATA_EXPORT, CONTENT_PAGE } from './routes/routes';

const App = () => {
  return (
    <Router>
     <Routes>
        <Route 
          path={HOME} 
          element={<HomePage />} 
        />
        <Route 
          path={MAP} 
          element={<MapPage />} 
        />
        <Route 
          path={DASHBOARD} 
          element={<Dashboard />} 
        />
        <Route 
          path={DATA_EXPORT} 
          element={<DataExportPage />}
        />
        <Route 
          path={CONTENT_PAGE} 
          element={<ContentPage />} 
        />
      </Routes>
    </Router>
  );
};

export default App;