import axios from 'axios';

const PLANNING_DATA_API_URL = '/api/proxy/planning-data';
const OS_NAMES_API_URL = '/api/search/os-names';

// Function to fetch planning data from the API
export const getPlanningData = async (params) => {
    try {
        const response = await axios.get(PLANNING_DATA_API_URL, {
            params,
            timeout: 10000
        });
        if (response.status !== 200) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
    return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

// Function to fetch OS names from the API
export const getOSNames = async (query) => {
    try {
        const response = await axios.get(OS_NAMES_API_URL, {
            params: { query },
            timeout: 10000
        });
        if (response.status !== 200) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }
        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};