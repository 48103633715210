import { useState } from 'react';
import { formatDatasetName } from '../../../utils/FormatDataSetName';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleChevronDown, faCircleChevronUp} from '@fortawesome/free-solid-svg-icons';
import styles from './InfoPanelAccordion.module.css';

// InfoPanelAccordion component: A collapsible panel that displays information in an accordion style
const InfoPanelAccordion = ({ index, entity }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        toggleAccordion();
      }
    };

  return (
    <div className={styles.accordion}>
    <div className={styles.accordionHeader}>
    <label 
    htmlFor={`dataset-${index}`} 
    data-testid={`dataset-name-${index}`}
    >              
        {`${formatDatasetName(entity?.dataset)}${entity?.name ? ` - ${entity.name}` : ''}`}          
    </label>
      <span onClick={toggleAccordion} onKeyDown={handleKeyDown} role='button' tabIndex={0}>
        {isOpen ? 
        <FontAwesomeIcon className={styles.accordionIcon} icon={faCircleChevronDown} />
        : 
        <FontAwesomeIcon className={styles.accordionIcon} icon={faCircleChevronUp} />
        }
        </span>
    </div>
    {isOpen && (
        <div>
            {entity['entry-date'] && <p>Entry Date: {entity['entry-date']}</p>}
            {entity['start-date'] && <p>Start Date: {entity['start-date']}</p>}
            {entity['end-date'] && <p>End Date: {entity['end-date']}</p>}
            {entity.typology && <p>Typology: {entity.typology}</p>}
            {entity.reference && <p>Reference: {entity.reference}</p>}
            {entity.notes && <p>Notes: {entity.notes}</p>}
        </div>
    )}
  </div>
  );
};

export default InfoPanelAccordion;
