import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

// TileCardItem component: Represents a single item in the TileCard component
// Props:
// - link: URL to navigate to when the item is clicked
// - icon: FontAwesome icon to display
// - title: Title text for the item
// - description: Description text for the item
const TileCardItem = ({ link, icon, title, description }) => {
    return (
        <div className="tile-card_item" data-testid="tile-card-item">
            <a href={link} className="tile-card-item_link" data-testid="tile-card-item-link">
                <div className="tile-card-item_bg" data-testid="tile-card-item-bg"></div>
                <div className="tile-card-item_content" data-testid="tile-card-item-content">
                    <FontAwesomeIcon icon={icon} data-testid="tile-card-item-icon" />
                    <p className="tile-card-title" data-testid="tile-card-item-title">{title}</p>
                    <p className="tile-card-description" data-testid="tile-card-item-description">{description}</p>
                </div>
            </a>
        </div>
    );
};

// Prop types validation
TileCardItem.propTypes = {
    link: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default TileCardItem;