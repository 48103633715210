const datasetNames = {
    "dataset_names": [ "address",
    "agricultural-land-classification",
    "ancient-woodland",
    "ancient-woodland-status",
    "area-of-outstanding-natural-beauty",
    "article-4-direction",
    "article-4-direction-area",
    "article-4-direction-rule",
    "asset-of-community-value",
    "attribution",
    "battlefield",
    "best-and-most-versatile-agricultural-land",
    "biodiversity-net-gain-assessment",
    "border",
    "brownfield-land",
    "brownfield-site",
    "buffer-zone",
    "building-preservation-notice",
    "built-up-area",
    "category",
    "central-activities-zone",
    "certificate-of-immunity",
    "coastal-change-management-area",
    "column",
    "common-land-and-village-green",
    "community-infrastructure-levy-schedule",
    "company",
    "conservation-area",
    "conservation-area-document",
    "conservation-area-document-type",
    "contaminated-land",
    "contribution-funding-status",
    "contribution-purpose",
    "control-of-major-accident-hazards-site",
    "default",
    "default-value",
    "design-code",
    "design-code-area",
    "design-code-area-type",
    "design-code-characteristic",
    "design-code-rule",
    "design-code-rule-category",
    "design-code-status",
    "developer-agreement",
    "developer-agreement-contribution",
    "developer-agreement-transaction",
    "developer-agreement-type",
    "development-corporation",
    "development-metric",
    "development-plan",
    "development-plan-boundary",
    "development-plan-boundary-type",
    "development-plan-document",
    "development-plan-document-type",
    "development-plan-event",
    "development-plan-geography",
    "development-plan-geography-type",
    "development-plan-status",
    "development-plan-timetable",
    "development-plan-type",
    "development-policy",
    "development-policy-area",
    "development-policy-category",
    "development-policy-metric",
    "document",
    "document-type",
    "educational-establishment",
    "employment-allocation",
    "entity",
    "entity-organisation",
    "expect",
    "expectation",
    "fact",
    "fact-resource",
    "flood-risk-level",
    "flood-risk-type",
    "flood-risk-zone",
    "flood-storage-area",
    "forest-inventory",
    "government-organisation",
    "green-belt",
    "green-belt-core",
    "guardianship-site-and-english-heritage-site",
    "gypsy-and-traveller-site",
    "heritage-action-zone",
    "heritage-at-risk",
    "heritage-coast",
    "historic-non-designed-rural-landscape-local-landscape-area",
    "historic-stone-quarry",
    "housing-allocation",
    "hs2-safeguarded-area",
    "infrastructure-funding-statement",
    "infrastructure-project",
    "infrastructure-project-decision",
    "infrastructure-project-document",
    "infrastructure-project-document-type",
    "infrastructure-project-event",
    "infrastructure-project-log",
    "infrastructure-project-type",
    "internal-drainage-board",
    "internal-drainage-district",
    "licence",
    "listed-building",
    "listed-building-grade",
    "listed-building-outline",
    "local-area-requirements",
    "local-authority",
    "local-authority-district",
    "local-authority-type",
    "local-enterprise-partnership",
    "local-green-space",
    "local-nature-recovery-strategy",
    "local-nature-reserve",
    "local-plan",
    "local-plan-boundary",
    "local-plan-document",
    "local-plan-document-type",
    "local-plan-event",
    "local-plan-timetable",
    "local-planning-authority",
    "local-resilience-forum",
    "local-resilience-forum-boundary",
    "locally-listed-building",
    "london-square",
    "long-established-woodland",
    "long-protected-woodland",
    "main-river",
    "metropolitan-open-land",
    "mineral-safeguarding-area",
    "national-nature-reserve",
    "national-park",
    "national-park-authority",
    "nature-improvement-area",
    "neighbourhood-forum",
    "non-designated-and-locally-listed-historic-asset",
    "non-designated-archeology-asset-of-national-importance",
    "nonprofit",
    "nuclear-safety-zone",
    "old-entity",
    "old-resource",
    "open-space",
    "ownership-status",
    "parish",
    "park-and-garden",
    "park-and-garden-grade",
    "passenger-transport-executive",
    "permitted-development-right",
    "permitted-development-right-part",
    "phase",
    "planning-application-category",
    "planning-application-condition",
    "planning-application-decision",
    "planning-application-document",
    "planning-application-log",
    "planning-application-status",
    "planning-application-type",
    "planning-condition",
    "planning-condition-purpose",
    "planning-condition-target",
    "planning-condition-type",
    "planning-decision",
    "planning-decision-type",
    "planning-development-category",
    "planning-permission-status",
    "planning-permission-type",
    "policy",
    "prefix",
    "proposed-ramsar-site",
    "protected-land",
    "protected-view",
    "protected-wreck-site",
    "public-authority",
    "public-safety-zone-around-airport",
    "ramsar",
    "realm",
    "region",
    "regional-park-authority",
    "safeguarded-aerodrome",
    "safeguarded-military-explosives-site",
    "safeguarded-wharf",
    "safety-hazard-area",
    "scheduled-monument",
    "self-and-custom-buildarea",
    "site-category",
    "site-of-special-scientific-interest",
    "special-area-of-conservation",
    "special-protection-area",
    "street",
    "suitable-alternative-green-space",
    "title-boundary",
    "transport-access-node",
    "transport-under-tcpa-route",
    "tree",
    "tree-preservation-order",
    "tree-preservation-zone",
    "tree-preservation-zone-type",
    "uprn",
    "ward",
    "waste-authority",
    "wildbelt",
    "wildlife",
    "world-heritage-site",
    "world-heritage-site-buffer-zone"
     ]
    };
export default datasetNames;